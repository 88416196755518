import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';

type FlowState = {
  teacher: string;
  topic?: string;
  quiz?: Question[];
  index: number;
  start: number;
  loading?: boolean;
};

type Question = {
  label: string;
  choices: string[];
  answer?: string;
};

const nb_questions = 5;

const topics: Question = {
  "label": "What you would like to learn now?",
  "choices": [
    "Mathematics",
    "Science",
    "Human body",
    "Animal facts",
    "Geography",
    "Planets and Universe",
  ]
};

export default function App() {
  return (
    <Router>
      <div id="app">
        <div id="main">
          <Routes>
            <Route path="/" element={ <Teacher topics={topics} /> } />            
          </Routes>
        </div>
      </div>
    </Router>
  );
};

const Teacher = ({ topics }: { topics: Question }) => {
  const [state, setState] = useState<FlowState>({
    teacher: "",
    start: 0,
    index: 0,
  });

  const select = (answer: string) => {
    setState({...state, loading: true});    
    
    let teacher;
    if (state.topic === undefined) {
      teacher = "Let's start with " + answer + "!";
    } else {
      const question = state.quiz![state.index];
      teacher = (question.answer === answer) ? 'Correct!' : 'Wrong! The answer is ' + question.answer;
    }

    const newState = {...state, teacher: teacher};
    if (state.topic === undefined) {
      const topic: string = answer;
      
      // fetch the quiz located at /public/quiz/5th/${topic}.json
      fetch(`/quiz/5th/${topic}.json`).then((questions) => {
        return questions.json();
      }).then((resp: any) => {
        const quiz: Question[] = resp.questions;
        const start = 0;
        setState({...newState, topic, quiz, start, index: start, loading: false});
      });
    } else if ((1 + state.index - state.start) < nb_questions) {
      setState({...newState, index: state.index + 1, loading: false}); 
    } else {
      setState({...newState, topic: undefined, loading: false});
    }
  };

  if (state.loading === true) {
    return <div className="question">Loading...</div>;
  }

  let question: Question;
  if (state.topic === undefined) {
    question = topics;
  } else {
    question = state.quiz![state.index];
  }

  if (question === undefined) {
    console.log('state', state);
    return <>
      BOOM! Bug Report :/
      <p>Topic: {state.topic}</p>
      <p>Quiz size: {state.quiz!.length}</p>
      <p>Index: {state.index}</p>
    </>;
  }

  return <>
    <div className="question">
      <p>{state.teacher!} {question.label}</p>
      <Selection choices={question.choices || []} callback={select} />
    </div>
  </>;
};

const Selection = ({ choices, callback } : { choices : string[], callback: (answer: string) => void }) => {
  const onSubmit = (e: any) => {
    e.preventDefault();
    const answer = e.target.choice.value
    callback(answer);
  };

  return <>
    <form onSubmit={onSubmit}>
      {choices.map((choice: string, i: number) => (      
        <div key={choice}>
          <input type="radio" name="choice" value={choice} />
          <label>{choice}</label>
        </div>
      ))}
      <button type="submit">Submit</button>
    </form>
  </>;
};